<template>
   <div class="social-networks" v-if="socialNetworks">
        <a 
            v-for="( social, index ) in socialNetworks" 
            :href="social.social_network" 
            :key="index"
            target="_blank"
        >
            <component
                :is="getSocialComponent(social.network)"
                :fill="cssVar()"
                size="1.4rem"
            />
            <q-tooltip 
            v-if="isBigScreen"
            self="top middle" :offset="[0, 5]"
            >
                Meu {{ social.network }}
            </q-tooltip>
        </a>
   </div>
</template>
<script setup>
import { defineProps, defineAsyncComponent } from "vue";

const instagramLogo = defineAsyncComponent(() => import('@/assets/icons/components/instagram.vue'))
const twitterLogo = defineAsyncComponent(() => import('@/assets/icons/components/twitter.vue'))
const facebookLogo = defineAsyncComponent(() => import('@/assets/icons/components/facebook.vue'))

defineProps({
  socialNetworks: Object,
  isBigScreen: Boolean // Para em smarthphones não exibir o tooltip
});

// Mapeando os nomes das redes sociais aos componentes
const socialComponentMap = {
  instagram: instagramLogo,
  twitter: twitterLogo,
  facebook: facebookLogo,
};

// Função para retornar o componente correto de acordo com a rede social
const getSocialComponent = (network) => {
  return socialComponentMap[network.toLowerCase()] || null;
}

const cssVar = () => {
  return getComputedStyle(document.documentElement).getPropertyValue('--primary-custom-color');
};
</script>
<style scoped>
    .social-networks {
        display: flex;
        gap: 12px;
        margin-top: 7px;
    }

    @media ( min-width: 640px ) {
        .social-networks {
            margin-top: 12px;
        }
    }
</style>